import { executeApi, useApi } from "../../composables/useApi";
import { computed } from "vue";
import { useSubscriptionInfoStore } from "../../stores/subscriptionInfoStore";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import { navigateTo } from "nuxt/app";
import { useModal } from "vue-final-modal";
import RestartModal from "../../components/content/modal/subscription/Restart.vue";
import { useMemberSessionStore } from "../../stores/memberSessionStore";

export default () => {
  const api = useApi();
  const { setSubscriptionInfo } = useSubscriptionInfoStore();
  const { isLoggedIn } = storeToRefs(useMemberSessionStore());

  const { subscriptionInfo } = storeToRefs(useSubscriptionInfoStore());

  const fetchSubscriptionInfo = () => {
    executeApi(async () => {
      const { data } = await api.memberData.subscription();
      setSubscriptionInfo(data);
    });
  };

  const getRemainingCredits = () => {
    return new Promise(async (resolve, reject) => {
      let remainingcredits = 0;

      try {
        await executeApi(async () => {
          const { data } = await api.memberData.subscription();
          const { freeTotalPassCount, freeUsedPassCount } = data;

          if (!freeTotalPassCount || !freeUsedPassCount) {
            resolve(0);
            return;
          }

          remainingcredits = freeTotalPassCount - freeUsedPassCount;
          resolve(remainingcredits);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const myPlanString = computed(() => {
    const { status } = subscriptionInfo.value;
    if (status === "inactive") {
      return "Basic";
    }

    if (status === "trialing") {
      return "Free trial";
    }

    if (status === "active") {
      return "College Prep";
    }
  });

  const myPlanButtonString = computed(() => {
    const { status, freeTrial } = subscriptionInfo.value;

    if (!isLoggedIn.value) {
      return "Start free trial";
    }

    if (status === "inactive") {
      if (!freeTrial) {
        return "Start free trial";
      } else {
        return "Upgrade plan";
      }
    }

    if (status === "trialing") {
      return "Detail";
    }

    if (status === "active") {
      return "Detail";
    }
  });

  const myPaymentInterval = computed(() => {
    const { amount, interval } = subscriptionInfo.value;
    return `$${amount / 100}/${interval === "year" ? "yr" : "mo"}`;
  });

  const leftTrialDay = computed(() => {
    const { status, endDay } = subscriptionInfo.value;
    if (status !== "trialing") {
      return "";
    }
    const now = dayjs();
    const end = dayjs(endDay);

    console.log(now, end);

    return end.diff(now, "day");
  });

  const convertPaymentDateFormat = (date) => {
    return dayjs(date).format("DD MMM[.] YYYY");
  };

  const handleEditPaymentMethod = () => {
    executeApi(async () => {
      const {
        data: { url },
      } = await api.memberData.getCustomerPotalUrl();
      console.log(url);
      navigateTo(url, { external: true, open: { target: "_blank" } });
    });
  };

  const handleRestart = async () => {
    await executeApi(async () => {
      const {
        data: { restart },
      } = await api.memberData.restartSubscription();

      if (restart) {
        const { open, destroy } = useModal({
          component: RestartModal,
          attrs: {
            onConfirm: () => {
              destroy();
            },
            onClosed: () => {
              fetchSubscriptionInfo();
            },
          },
        });

        open();
      }
    });
  };

  const insertAtFirstSpace = (inputStr, insertStr) => {
    const spaceIndex = inputStr.indexOf(" ");

    if (spaceIndex === -1) return inputStr;

    return `${inputStr.slice(0, spaceIndex)}${insertStr}${inputStr.slice(
      spaceIndex
    )}`;
  };

  return {
    subscriptionInfo,
    myPlanString,
    myPlanButtonString,
    myPaymentInterval,
    leftTrialDay,
    getRemainingCredits,
    fetchSubscriptionInfo,
    convertPaymentDateFormat,
    handleEditPaymentMethod,
    handleRestart,
    insertAtFirstSpace,
  };
};
