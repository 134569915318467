<script setup>
const emit = defineEmits()

const closeModal = () => {
  emit('closed')
}

</script>

<template>
  <LayoutModal modal-id="ModalRestartSubscription" header-type="empty" max-width="400" :closeConfirm="false">
    <article class="modal-content p-a-24">
      <h1 class="text-title1 m-bottom-8">
        Thank You for Coming Back!
      </h1>
      <p class="text-body1 text-black2">
        Your subscription has successfully been restarted.
      </p>
      <div class="m-top-24 flex">
        <UiButton
          class="outline small full"
          @click="closeModal"
        >
          Get Started
        </UiButton>
      </div>
    </article>
  </LayoutModal>
</template>

<style lang="scss">
@media screen and (max-width: 450px) {
  .max-w-400 {
    max-width: 320px !important;
  }
}
</style>
